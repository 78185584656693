import { CaretDown } from "@phosphor-icons/react";
import paciente from "../../assets/paciente.png";
import cross from "../../assets/SAÚDE.png";
import styles from "./about.module.scss";

export default function About() {
  const renderCard = (title: string, description: string) => (
    <div className={styles.cardContainer}>
      <div className={styles.card}>
        <div className={styles.row}>
          <div className={styles.iconContainer}>
            <CaretDown size={24} weight="bold" color="#08824a" />
          </div>
          <div className={styles.column}>
            <span>{title}</span>
          </div>
        </div>
      </div>
      <div className={styles.description}>
        <p>{description}</p>
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      <img src={cross} alt="cross" className={styles.cross} />
      <div className={styles.aboutColumn}>
        {renderCard(
          "Missão",
          "Ser parceiro da sociedade na busca de soluções que melhorem a qualidade de vida de nossos associados. Investir em relações baseadas na confiança e na valorização do ser humano, modernizando o segmento de Planos Assistenciais através de um atendimento especializado e com preços acessíveis."
        )}

        {renderCard(
          "Visão",
          "Estar entre as melhores empresas de prestação de serviços de assistência familiar na Paraíba."
        )}
      </div>

      <div className={styles.imageContainer}>
        <img src={paciente} alt="paciente" />
      </div>
    </div>
  );
}
