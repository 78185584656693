import { CaretDown } from '@phosphor-icons/react';
import styles from './banner.module.scss';

function Banner() {
  
  const renderCard = (title: string, subtitle: string, description?: string, subdescription?: string) => (
    <div className={styles.card}>
      <div className={styles.row}>
        <div className={styles.iconContainer}>
          <CaretDown size={24} weight='bold' color='#08824a'  />
        </div>
        <div className={styles.column}>
          <span>{title}</span>
          <span>{subtitle}</span>
          <span className={styles.description}>{description}</span>
          <span className={styles.subdescription}>{subdescription}</span>

        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.banner}>
      <div className={styles.cardBanner}>
        {renderCard("A partir de", "R$ 4,00 por pessoa", "*Valor baseado na mensalidade", "do plano com até 6 pessoas")}
        {renderCard("Mais de 500 mil", "beneficiados")}
        {renderCard("Atuante em", "todo o estado.")}
      </div>
    </div>
  )
}

export default Banner