import { FilteredDataProps } from "../../../../types/types";
import ModalList from "../modal-list";
import styles from "./modalContent.module.scss";

export interface IModalContent {
  data: FilteredDataProps[];
}

function ModalContent({ data }: IModalContent) {
  return (
    <div className={styles.modalContent}>
      {data.length > 0 ? (
        <ModalList data={data} />
      ) : (
        <p style={{ textAlign: "center", marginTop: "2rem" }}>
          Nenhum resultado encontrado.
        </p>
      )}
    </div>
  );
}

export default ModalContent;
