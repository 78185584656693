import React from 'react';
import { RouterProvider } from 'react-router-dom';
import routes from './routes';
// import Home from "./pages/home";
// import Plans from "./pages/plans";
// import Contact from "./pages/contact";
// import Helpers from "./pages/helpers";

const App: React.FC = () => {
  return (
    <RouterProvider router={routes} />
  );
}

export default App;
