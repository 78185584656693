import React, { useState } from "react";
import { List } from "@phosphor-icons/react";
import { underlineTabs } from "../../constants/data";
import { Tabs } from "../../components/tabs";
import logo from "../../assets/logo.png";
import styles from "./navbar.module.scss";
import { useNavigate } from "react-router-dom";

interface NavbarProps {
  selectedTab: number;
  onTabSelect: (index: number) => void;
}

const Navbar: React.FC<NavbarProps> = ({ selectedTab, onTabSelect }) => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const handleOpenMenu = () => {
    setOpen(!open);
  };

  const handleTabSelect = (index: number) => {
    onTabSelect(index);
    setOpen(false);
  };

  const handleHomeNavigation = () => {
    onTabSelect(0);
    navigate("/");
  };

  return (
    <>
      <div className={styles.header}>
        <img
          src={logo}
          alt={""}
          style={{ height: "50%", paddingLeft: "2rem" }}
          onClick={handleHomeNavigation}
        />

        <div className={styles.headerRight}>
          <Tabs
            array={underlineTabs}
            onTabSelect={handleTabSelect}
            selectedTab={selectedTab}
          />
          <span></span>
        </div>

        <button className={styles.buttonMobile} onClick={handleOpenMenu}>
          <List size={32} />
        </button>
      </div>

      <div className={`${styles.header2} ${open ? styles.mobileHeader : ""}`}>
        <img
          src={logo}
          alt={""}
          style={{ height: "10%", paddingTop: "2rem" }}
        />

        <Tabs
          array={underlineTabs}
          onTabSelect={handleTabSelect}
          selectedTab={selectedTab}
          column={true}
        />

        <button className={styles.buttonMobile} onClick={handleOpenMenu}>
          <div
            style={{
              display: "flex",
              gap: "1rem",
              backgroundColor: "#08824a",
              padding: "0.5rem",
              marginLeft: "2rem",
              borderRadius: "15px",
              cursor: "pointer",
              width: "8rem",
              height: "1.3rem",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: "bold",
            }}
          >
            <span>FECHAR</span>
          </div>
        </button>
      </div>
    </>
  );
};

export default Navbar;
