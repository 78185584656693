import { useRef, useState } from "react";
import { FilteredDataProps } from "../types/types";
import { data } from "../constants/data";

const useAuthorizedEstablishments = () => {
  const [filteredData, setFilteredData] = useState<FilteredDataProps[]>(data);
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (inputRef.current) {
      inputRef.current.value = event.target.value;
    }
  };

  const removeAccents = (str: string) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const handleSearch = () => {
    if (inputRef.current) {
      const searchTerm = removeAccents(inputRef.current.value.toLowerCase());
      const filtered = data.filter(
        (item) =>
          removeAccents(item.especialidade.toLowerCase()).includes(searchTerm)
      );
      setFilteredData(filtered);
      setIsOpen(true);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return {
    filteredData,
    isOpen,
    inputRef,
    setIsOpen,
    handleInputChange,
    handleSearch,
    handleKeyDown,
  };
};

export default useAuthorizedEstablishments;
