import { CaretDown, Check } from "@phosphor-icons/react";
import coracao from "../../assets/15.png";
import heart from "../../assets/coracao_tela1_ladebaixo.png"
import styles from "./info.module.scss";

export default function Info() {
  return (
    <div className={styles.info}>
      <img className={styles.coracao} src={coracao} alt="coracao" />
      <div className={styles.cardContainer}>
        <div className={styles.card}>
          <div className={styles.row}>
            <div className={styles.iconContainer}>
              <CaretDown size={24} weight="bold" color="#08824a" />
            </div>
            <div className={styles.column}>
              <span>Valores</span>
            </div>
          </div>
        </div>

        <div className={styles.description}>
          <div className={styles.checkContainer}>
            <div className={styles.check}>
              <Check color="#08824a" size={24} weight="bold" />
            </div>
            <p>
              <b>Ética, Honestidade, Integridade:</b>
              <br /> princípios fundamentais de todas as ações;
            </p>
          </div>

          <div className={styles.checkContainer}>
            <div className={styles.check}>
              <Check color="#08824a" size={24} weight="bold" />
            </div>
            <p>
            <b>Eficiência, Liderança, Inovação:</b> com foco nos resultados;
            </p>
          </div>

          <div className={styles.checkContainer}>
            <div className={styles.check}>
              <Check color="#08824a" size={24} weight="bold" />
            </div>
            <p>
              
            <b>Organização:</b> comprometimento com o coletivo; 
            </p>
          </div>

          <div className={styles.checkContainer}>
            <div className={styles.check}>
              <Check color="#08824a" size={24} weight="bold" />
            </div>
            <p>
            <b>Profissionalismo:</b> Atendimento voltado as dificuldades
              <br /> ocasionadas pelo Luto;
            </p>
          </div>

          <div className={styles.checkContainer}>
            <div className={styles.check}>
              <Check color="#08824a" size={24} weight="bold" />
            </div>
            <p>
            <b>Responsabilidade social:</b> parcerias, meio ambiente, sustentabilidade.
            </p>
          </div>
        </div>
      </div>

      <div className={styles.centerText}>
      <img src={heart} alt="coracao" />
      <img src={heart} alt="coracao" />
        <span>Cuidando e</span>
        <span>protegendo</span>
        <span>
          quem <b>você ama.</b>
        </span> 
      </div>
      <div className={styles.barDivider}></div>
    </div>
  );
}
