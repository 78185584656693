import { useEffect } from "react";

const useScrollReset = ( ) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

export default useScrollReset;