import style from "./plans.module.scss";
import mapa_paraiba from "../../assets/mapa-paraiba.png";
import { FormContact } from "../../components/form_contact";
import { ListPlan } from "../../components/list_plan";
import Testimony from "../../components/testimony";
import { testimonyData } from "../../constants/data";
import useScrollReset from "../../hooks/useScrollReset";
import woman_tlmkt from "../../assets/woman_tlmkt.png";
import balaoverde from "../../assets/Shape copiar 2.png";

export interface ListPlanProps {
  tipo: "prata" | "ouro" | "diamante" | "rubi" | "esmeralda";
}

const planTypes: ListPlanProps["tipo"][] = ["prata", "ouro"];

const secondPlanType: ListPlanProps["tipo"][] = [
  "diamante",
  "rubi",
  "esmeralda",
];

const Plans = () => {
  useScrollReset();

  return (
    <div className={style.root}>
      <div
        className={style.map}
        style={{ backgroundImage: `url(${mapa_paraiba})` }}
      ></div>
      <div className={style.container}>
        <div className={style.faleconosco}>
          <div className={style.text}>
            <h4> DÚVIDAS? </h4>
            <p>Fale conosco:</p>
          </div>
          <FormContact />
        </div>
        <img src={woman_tlmkt} alt="" className={style.woman} />
      </div>
      <div className={style.wave}>
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
            className={style.wave_fill}
          ></path>
        </svg>
      </div>

      <div className={style.listPlans}>
        <div className={style.listPlansTexts}>
          <h1>PLANOS</h1>
          <h6>CLIQUE E SAIBA MAIS</h6>
        </div>
        <div className={style.plansDescription}>
          {planTypes.map((tipo, index) => (
            <ListPlan key={index} tipo={tipo} />
          ))}
        </div>
        
        <div className={style.plansDescription}>
          {secondPlanType.map((tipo, index) => (
            <ListPlan key={index} tipo={tipo} />
          ))}
        </div>
        
      </div>

      <div className={style.rodape}>
        <div className={style.rodapeText}>
          <span>O que estão</span>
          <span>
            falando do <b>PLAFAM:</b>
          </span>
        </div>

        <Testimony data={testimonyData} />
        <img className={style.balaoverde} src={balaoverde} />
      </div>
    </div>
  );
};

export default Plans;
