import { IModalContent } from "../modal-content";
import styles from "./modalList.module.scss";

function ModalList({ data }: IModalContent) {
  return (
    <div className={styles.authorizedList}>
      {data.map((item, index) => (
        <div className={styles.listContainer} key={index}>
          <h6>{item.especialidade}</h6>
          <p>{`${item.localidade} - ${item.cidade}`}</p>
          <p>Contato: {item.contato ? `${item.contato}` : "Não disponível"}</p>
        </div>
      ))}
    </div>
  );
}

export default ModalList;
