import oldMan from "../assets/smiley-old-man-with-glasses.png";
import oldWoman from "../assets/portrait-congolese-woman.png";
import otherWoman from "../assets/ana clara foto.png";

export const textos = [
  "Planos a partir de R$4",
  "Inumeros benefícios durante a vida",
  "O seu sossego pós vida",
];

export const underlineTabs = [
  {
    text: "INÍCIO",
    path: "/",
  },
  {
    text: "PLAFAM",
    path: "/plafam",
  },
  {
    text: "CREDENCIADOS",
    path: "/credenciados",
  },
  {
    text: "PLANOS",
    path: "/planos",
  },
];

export const data = [
  {
    "localidade": "STUDIO L FIT",
    "cidade": "JUAREZ TAVORA",
    "especialidade": "ACADEMIA",
    "contato": ""
  },
  {
    "localidade": "BOA FORMA",
    "cidade": "PICUÍ",
    "especialidade": "ACADEMIA",
    "contato": ""
  },
  {
    "localidade": "ALTA FORMA",
    "cidade": "QUEIMADAS",
    "especialidade": "ACADEMIA",
    "contato": ""
  },
  {
    "localidade": "FEMME FITNESS",
    "cidade": "SOLEDADE",
    "especialidade": "ACADEMIA",
    "contato": ""
  },
  {
    "localidade": "PHYSICU´S",
    "cidade": "SERRA BRANCA",
    "especialidade": "ACADEMIA",
    "contato": ""
  },
  {
    "localidade": "STYLLO FITNESS",
    "cidade": "SERRA BRANCA",
    "especialidade": "ACADEMIA",
    "contato": ""
  },
  {
    "localidade": "FISIOVITTA",
    "cidade": "CARAÚBAS",
    "especialidade": "ACUMPUTURA",
    "contato": ""
  },
  {
    "localidade": "TERAPIAS INTEGRADAS ESPAÇO VOCÊ",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ACUMPUTURA",
    "contato": "83-981474165"
  },
  {
    "localidade": "AMANDA PESSOA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ACUPUNTURA",
    "contato": "83-982125926"
  },
  {
    "localidade": "DRA MARIA ALZIRA DE OLIVEIRA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ALERGIA RESPIRATÓRIA",
    "contato": "83-987844554"
  },
  {
    "localidade": "ORTOCENTER JK",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ANGIOLOGIA",
    "contato": "83-21578983"
  },
  {
    "localidade": "NASCIMENTO E BURITI MÉDICOS LTDA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ANGIOLOGIA",
    "contato": "83-998609559"
  },
  {
    "localidade": "CLINICA VASCULARE",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ANGIOLOGIA",
    "contato": "83-33222784"
  },
  {
    "localidade": "DR. BELCHIOR DE MEDEIROS LUCENA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ANGIOLOGIA",
    "contato": "83-987005778"
  },
  {
    "localidade": "DR BRENO DE MEDEIROS LUCENA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ANGIOLOGIA",
    "contato": "83-987005778"
  },
  {
    "localidade": "DR HENRIQUE COSTA FILHO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ANGIOLOGIA",
    "contato": "83-33213653"
  },
  {
    "localidade": "FUNERÁRIA SÃO JOÃO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ASSISTÊNCIA PÓS VIDA",
    "contato": "83-33218988"
  },
  {
    "localidade": "BODEGA DO VAVÁ",
    "cidade": "AREIA",
    "especialidade": "BODEGA",
    "contato": ""
  },
  {
    "localidade": "ORTOCENTER JK",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CARDIOLOGIA",
    "contato": "83-21578983"
  },
  {
    "localidade": "DRA SANDRA NISIA DE ANDRADE",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CARDIOLOGIA",
    "contato": "83-996063333"
  },
  {
    "localidade": "DR FRANCISCO CLEANTRO BRASILEIRO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CARDIOLOGIA",
    "contato": "83-988117154"
  },
  {
    "localidade": "DR JOCELIO LOUREIRO CELINO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CARDIOLOGIA",
    "contato": "83-988686497"
  },
  {
    "localidade": "DR IRAMIRTON LIANDRO BEZERRA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CARDIOLOGIA",
    "contato": "83-988294678"
  },
  {
    "localidade": "DR. FELIPE RODRIGUES PINTO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CARDIOLOGIA",
    "contato": "83-988448068"
  },
  {
    "localidade": "DR MIRABEAU MARANHÃO PINTO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CARDIOLOGIA",
    "contato": "83-33411846"
  },
  {
    "localidade": "CLÍNICA PRICARDIO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CARDIOLOGIA",
    "contato": "83-33414793"
  },
  {
    "localidade": "DR JOSÉ ARNOBIO ALMEIDA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CARDIOLOGIA",
    "contato": "83-33422644"
  },
  {
    "localidade": "DR BASILIO SERRANO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CARDIOLOGIA",
    "contato": "83-21537141"
  },
  {
    "localidade": "CLÍNICA PRICARDIO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CARDIOLOGIA PEDIÁTRICA",
    "contato": "83-33414793"
  },
  {
    "localidade": "DR JOSÉ ARNOBIO ALMEIDA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CARDIOLOGIA PEDIÁTRICA",
    "contato": "83-33422644"
  },
  {
    "localidade": "SÃO JOÃO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CENTRAL DE VELÓRIO",
    "contato": "83-33218988"
  },
  {
    "localidade": "DR LEANDRO TORRES",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CIRURGIA BARIÁTRICA",
    "contato": "83-33227845"
  },
  {
    "localidade": "DR PEDRO SAULO PEREIRA DOS SANTOS",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CIRURGIA BARIÁTRICA",
    "contato": "83-33416623"
  },
  {
    "localidade": "ORTOCENTER JK",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CIRURGIA BUCO-MAXILO-FACIAL",
    "contato": "83-21578983"
  },
  {
    "localidade": "DR. SHUBERT COSTA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CIRURGIA DA COLUNA",
    "contato": "83-33412560"
  },
  {
    "localidade": "DR RODRIGO PORTO AMORIM GUEDES",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CIRURGIA DA COLUNA",
    "contato": "83-33414666"
  },
  {
    "localidade": "DR IVO MARQUES",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CIRURGIA DE CABEÇA",
    "contato": "83-33411183"
  },
  {
    "localidade": "CENTRO MÉDICO JPBBRANDÃO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CIRURGIA DE CABEÇA",
    "contato": "83-33412020"
  },
  {
    "localidade": "CENTRO MÉDICO JPBBRANDÃO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CIRURGIA DE PESCOÇO",
    "contato": "83-33411183"
  },
  {
    "localidade": "DR. PEDRO SAULO PEREIRA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CIRURGIA DIGESTIVA",
    "contato": "83-33416623"
  },
  {
    "localidade": "DR. LEANDRO TORRES",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CIRURGIA DIGESTIVA",
    "contato": "83-33227845"
  },
  {
    "localidade": "DR. AGUINALDO PEREIRA JUNIOR",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CIRURGIA DO JOELHO",
    "contato": "83-33412566"
  },
  {
    "localidade": "DR. VALTH GUIMARÃES",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CIRURGIA DO JOELHO",
    "contato": "83-33412560"
  },
  {
    "localidade": "DR. JOSÉ RENNÁ",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CIRURGIA DO OMBRO",
    "contato": "83-33412566"
  },
  {
    "localidade": "DR. PEDRO SAULO PEREIRA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CIRURGIA GERAL",
    "contato": "83-33416623"
  },
  {
    "localidade": "CLINICA SÃO CAMILO DIAGNÓSTICO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CIRURGIA GERAL",
    "contato": "83-33227854"
  },
  {
    "localidade": "DRA. FRANCIMAR DE SOUSA BURITI",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CIRURGIA PLÁSTICA",
    "contato": "83-33415543"
  },
  {
    "localidade": "DR. JUAREZ RITTER",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CIRURGIA TORÁXICA",
    "contato": "83-33222354"
  },
  {
    "localidade": "DR. RIVALDO FILHO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CIRURGIA TORÁXICA",
    "contato": "83-33222354"
  },
  {
    "localidade": "DR. WAGNER FALCÃO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CIRURGIA TORNOZELO E PÉ",
    "contato": "83-33412560"
  },
  {
    "localidade": "ORTOCENTER JK",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CIRURGIA VASCULAR",
    "contato": "83-21578983"
  },
  {
    "localidade": "NASCIMENTO E BURITI MÉDICOS LTDA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CIRURGIA VASCULAR",
    "contato": "83-998609559"
  },
  {
    "localidade": "DR. HENRIQUE COSTA FILHO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CIRURGIA VASCULAR",
    "contato": "83-33213653"
  },
  {
    "localidade": "ECOCLINICA DE PATOS",
    "cidade": "PATOS",
    "especialidade": "CLINICA GERAL",
    "contato": ""
  },
  {
    "localidade": "DR. SEBASTIÃO",
    "cidade": "PICUÍ",
    "especialidade": "CLINICAS",
    "contato": ""
  },
  {
    "localidade": "POLICLINICA",
    "cidade": "QUEIMADAS",
    "especialidade": "CLINICAS",
    "contato": ""
  },
  {
    "localidade": "CEMOR",
    "cidade": "QUEIMADAS",
    "especialidade": "CLINICAS",
    "contato": ""
  },
  {
    "localidade": "CLIMÉDICA",
    "cidade": "SANTA LUZIA",
    "especialidade": "CLINICAS",
    "contato": ""
  },
  {
    "localidade": "POLICLINICA UNIVIDA DA SAÚDE",
    "cidade": "SÃO JOÃO DO CARIRI",
    "especialidade": "CLINICAS",
    "contato": ""
  },
  {
    "localidade": "POLICLINICA",
    "cidade": "SÃO VICENTE DO SERIDÓ",
    "especialidade": "CLINICAS",
    "contato": ""
  },
  {
    "localidade": "CAMED",
    "cidade": "SOLÂNEA",
    "especialidade": "CLINICAS",
    "contato": ""
  },
  {
    "localidade": "CLINICA MÉDICA EULIBIO CORPO E MENTE",
    "cidade": "SOLÂNEA",
    "especialidade": "CLINICAS",
    "contato": ""
  },
  {
    "localidade": "POLICLINICA MAIS SAÚDE",
    "cidade": "SOLEDADE",
    "especialidade": "CLINICAS",
    "contato": ""
  },
  {
    "localidade": "AME",
    "cidade": "TAPEROÁ",
    "especialidade": "CLINICAS",
    "contato": ""
  },
  {
    "localidade": "MEDMAIS",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CLINICAS",
    "contato": "83-981193155"
  },
  {
    "localidade": "EMAGRECENTRO ANTÔNIO FERNANDES",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CLINICAS",
    "contato": "83-986715800"
  },
  {
    "localidade": "PROMEDICA OROMEDIC",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CLINICAS",
    "contato": "83-31822900"
  },
  {
    "localidade": "BFORT",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CLINICAS",
    "contato": "83-33224531"
  },
  {
    "localidade": "DERMATO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CLINICAS",
    "contato": "83-33410065"
  },
  {
    "localidade": "CLINICA VASCULARE",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CLINICAS",
    "contato": "83-33222784"
  },
  {
    "localidade": "CLINICA DIGEST",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CLINICAS",
    "contato": "83-33429410"
  },
  {
    "localidade": "CLINICA MATERNA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CLINICAS",
    "contato": "83-996860106"
  },
  {
    "localidade": "CLINFETO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CLINICAS",
    "contato": "83-30996090"
  },
  {
    "localidade": "CLINICA DO CORAÇÃO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CLINICAS",
    "contato": "83-998683220"
  },
  {
    "localidade": "CLINICA DR MAIA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CLINICAS",
    "contato": "83-33413854"
  },
  {
    "localidade": "CLINICA SÃO CAMILO DIAGNÓSTICO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CLINICAS",
    "contato": "83-33227854"
  },
  {
    "localidade": "CLÍNICA SÃO PAULO",
    "cidade": "AROEIRAS",
    "especialidade": "CLINICAS",
    "contato": ""
  },
  {
    "localidade": "BIOCLIN",
    "cidade": "BANANEIRAS",
    "especialidade": "CLINICAS",
    "contato": ""
  },
  {
    "localidade": "POLICLINICA",
    "cidade": "BOQUEIRÃO",
    "especialidade": "CLINICAS",
    "contato": "83-33911198"
  },
  {
    "localidade": "POLICLÍNICA",
    "cidade": "CUBATI",
    "especialidade": "CLINICAS",
    "contato": ""
  },
  {
    "localidade": "GINOMED",
    "cidade": "JUAZEIRINHO",
    "especialidade": "CLINICAS",
    "contato": ""
  },
  {
    "localidade": "DRA. TERESA CRISTINA MAYER DA NÓBREGA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CLINICO GERAL",
    "contato": "83-33416784"
  },
  {
    "localidade": "DR. FARIAS",
    "cidade": "AREIA",
    "especialidade": "CLINICO GERAL",
    "contato": ""
  },
  {
    "localidade": "DR. SEVERINO TAVARES DA SILVA",
    "cidade": "FAGUNDES",
    "especialidade": "CLINICO GERAL",
    "contato": ""
  },
  {
    "localidade": "DRA. LUCIANA VALESKA M. SALES",
    "cidade": "GADO BRAVO",
    "especialidade": "CLINICO GERAL",
    "contato": ""
  },
  {
    "localidade": "DRA. ANGELA MARIA TARGINO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "COLONOSCOPIA",
    "contato": "83-33415419"
  },
  {
    "localidade": "ENDOGASTRO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "COLONOSCOPIA",
    "contato": "83-33226122"
  },
  {
    "localidade": "SAÚDE E VIDA",
    "cidade": "NOVA FLORESTA",
    "especialidade": "CONSULTÓRIO MÉDICO",
    "contato": ""
  },
  {
    "localidade": "PSICOPEDAGÓGICO CLÍNICO",
    "cidade": "NOVA FLORESTA",
    "especialidade": "CONSULTÓRIO PSICOPEDAGÓGICO",
    "contato": ""
  },
  {
    "localidade": "ORTOCENTER JK",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "DERMATOLOGIA",
    "contato": "83-21578983"
  },
  {
    "localidade": "DRA HELANIA PEREIRA FAGUNDES",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "DERMATOLOGIA",
    "contato": "83-33410065"
  },
  {
    "localidade": "DR FRANCIMAR BURITI",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "DERMATOLOGIA",
    "contato": "83-33415543"
  },
  {
    "localidade": "DRA. OLGA MARIA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "DERMATOLOGIA",
    "contato": "83-33411846"
  },
  {
    "localidade": "DRA. ANA CECILIA ARAÚJO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "DERMATOLOGIA",
    "contato": "83-21535936"
  },
  {
    "localidade": "DRA. MARIA IZABEL ARRUDA MEIRA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "DERMATOLOGIA",
    "contato": "83-33417131"
  },
  {
    "localidade": "DRA. NATÁLIA DONÉ",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "DERMATOLOGIA",
    "contato": "83-21578983"
  },
  {
    "localidade": "DERMATO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "DERMATOLOGIA",
    "contato": "83-33410065"
  },
  {
    "localidade": "DRA. LUCIANA RABELLO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "DERMATOLOGIA",
    "contato": "83-21578983"
  },
  {
    "localidade": "ENDOVITALIS",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "DIAGNÓSTICO POR IMAGEM",
    "contato": "83-33216091"
  },
  {
    "localidade": "SPECTRO IMAGEM",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "DIAGNÓSTICO POR IMAGEM",
    "contato": "83-33213284"
  },
  {
    "localidade": "SER",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "DIAGNÓSTICO POR IMAGEM",
    "contato": "83-33411355"
  },
  {
    "localidade": "CAMPIMAGEM",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "DIAGNÓSTICO POR IMAGEM",
    "contato": "83-33411089"
  },
  {
    "localidade": "IMAGO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "DIAGNÓSTICO POR IMAGEM",
    "contato": "83-30639001"
  },
  {
    "localidade": "ECLIN",
    "cidade": "ESPERANÇA",
    "especialidade": "DIAGNÓSTICO POR IMAGEM",
    "contato": ""
  },
  {
    "localidade": "SEA LAN HOUSE",
    "cidade": "MONTADAS",
    "especialidade": "DISTRIBUIDORA DE ÁGUA MINERAL",
    "contato": ""
  },
  {
    "localidade": "RGB GÁS",
    "cidade": "BOQUEIRÃO",
    "especialidade": "DISTRIBUIDORA DE GÁS",
    "contato": ""
  },
  {
    "localidade": "ALMIR GÁS",
    "cidade": "ITATUBA",
    "especialidade": "DISTRIBUIDORA DE GÁS",
    "contato": ""
  },
  {
    "localidade": "NOVA FLORESTA GÁS",
    "cidade": "NOVA FLORESTA",
    "especialidade": "DISTRIBUIDORA DE GÁS",
    "contato": ""
  },
  {
    "localidade": "FRIGOGÁS",
    "cidade": "PEDRA LAVRADA",
    "especialidade": "DISTRIBUIDORA DE GÁS",
    "contato": ""
  },
  {
    "localidade": "SÃO JOSÉ",
    "cidade": "SERRA BRANCA",
    "especialidade": "DISTRIBUIDORA DE GÁS",
    "contato": ""
  },
  {
    "localidade": "ORTOCENTER JK",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ENDOCRINOLOGIA",
    "contato": "83-21578983"
  },
  {
    "localidade": "DRA. MARILIA MARQUES PEREIRA LIRA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ENDOCRINOLOGIA",
    "contato": "83-33226084"
  },
  {
    "localidade": "DR. JOSÉ ADAINO BARROS",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ENDOCRINOLOGIA",
    "contato": "83-33416784"
  },
  {
    "localidade": "DRA. RACHEL DE CASTRO LOUREIRO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ENDOCRINOLOGIA",
    "contato": "83-33224342"
  },
  {
    "localidade": "DR. J. EDMILSON LEITE JUNIOR",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ENDOCRINOLOGIA",
    "contato": "83-30555090"
  },
  {
    "localidade": "DR. ANTÔNIO FERNANDES",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ENDOCRINOLOGIA",
    "contato": "83-986715800"
  },
  {
    "localidade": "DRA. PAULA BEATRIZ DE OLIVEIRA SOARES",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ENDOCRINOLOGIA",
    "contato": "83-21482955"
  },
  {
    "localidade": "ENDOGASTRO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ENDOCRINOLOGIA",
    "contato": "83-33226122"
  },
  {
    "localidade": "PROGASTRO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ENDOCRINOLOGIA",
    "contato": "83-33416784"
  },
  {
    "localidade": "DR. PAULO EDUARDO ROBERTO DE ASSIS",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ENDOCRINOLOGIA",
    "contato": "83-33332215"
  },
  {
    "localidade": "DRA. ANA CAROLINA ARRUDA MEIRA BRITO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ENDOCRINOLOGIA",
    "contato": "83-33417131"
  },
  {
    "localidade": "DRA. MARILIA MARQUES PEREIRA LIRA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ENDOCRINOLOGIA",
    "contato": "83-33226084"
  },
  {
    "localidade": "ENDOGASTRO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ENDOSCOPIA",
    "contato": "83-33226122"
  },
  {
    "localidade": "PROGASTRO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ENDOSCOPIA",
    "contato": "83-33416784"
  },
  {
    "localidade": "IFPB",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ENDOSCOPIA",
    "contato": "83-33416996"
  },
  {
    "localidade": "GRAU TÉCNICO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ENSINO",
    "contato": "83-30880808"
  },
  {
    "localidade": "DR. CARLOS CANDIDO ROCHA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ESPECIALISTA EM JOELHO",
    "contato": "83-33414666"
  },
  {
    "localidade": "DR FRANCISCO VIEIRA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ESPECIALISTA EM JOELHO",
    "contato": "83-33414666"
  },
  {
    "localidade": "DR. GIOVANINI CÉSAR FIGUEIREDO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ESPECIALISTA EM JOELHO",
    "contato": "83-33414666"
  },
  {
    "localidade": "DR. RICARDO AMORIM GUEDES FILHO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ESPECIALISTA EM MÃO",
    "contato": "83-33414666"
  },
  {
    "localidade": "DR. MÁRCIO TADEU RODRIGUES",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ESPECIALISTA EM MÃO",
    "contato": "83-33210423"
  },
  {
    "localidade": "DRA. PAULA C. MEDEIROS",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ESPECIALISTA EM MÃO",
    "contato": "83-33414666"
  },
  {
    "localidade": "DR. MARCELIO H. SILVA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ESPECIALISTA EM OMBRO",
    "contato": "83-33414666"
  },
  {
    "localidade": "DR. ARISTOTALES NETO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ESPECIALISTA EM OMBRO",
    "contato": "83-33414666"
  },
  {
    "localidade": "DR. RODOLFO COIMBRA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ESPECIALISTA EM PÉ",
    "contato": "83-33414666"
  },
  {
    "localidade": "DR. MARCIO TADEU RODRIGUES",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ESPECIALISTA EM PUNHO",
    "contato": "83-33210423"
  },
  {
    "localidade": "DR. GIOVANINI CÉSAR FIGUEIREDO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ESPECIALISTA EM QUADRIL",
    "contato": "83-33414666"
  },
  {
    "localidade": "DR. GIOVANINI CÉSAR FIGUEIREDO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ESPECIALISTA EM QUADRIL",
    "contato": "83-33414666"
  },
  {
    "localidade": "DR. RICARDO AMORIM GUEDES FILHO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ESPECIALISTA EM QUADRIL",
    "contato": "83-33414666"
  },
  {
    "localidade": "DR. MARCIO TADEU RODRIGUES",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ESPECIALISTA EM QUADRIL",
    "contato": "83-33210423"
  },
  {
    "localidade": "MONSENHOR BORGES",
    "cidade": "ALAGOA GRANDE",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "FARMÁCIA DOS APOSENTADOS",
    "cidade": "ALAGOA NOVA",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "DROGARIA FARMACILIA",
    "cidade": "ALAGOA NOVA",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "DROGARIA BOM JESUS",
    "cidade": "ARARUNA",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "LAFARMA",
    "cidade": "ARARA",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "DROGRARIA PEDROSA",
    "cidade": "ARARA",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "CENTRAL FARMA",
    "cidade": "AREIA",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "FARMÁCIA NOVA VIDA",
    "cidade": "AROEIRAS",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "DROGARIA NOVA VIDA",
    "cidade": "AROEIRAS",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "MAIS SAÚDE",
    "cidade": "BARRA DE SÃO MIGUEL",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "SÃO MIGUEL",
    "cidade": "BARRA DE SÃO MIGUEL",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "BOA VISTA",
    "cidade": "BARRA DE SÃO MIGUEL",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "FARMÁCIA CENTRAL",
    "cidade": "CACIMBA DE DENTRO",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "BAIRRO NOVO",
    "cidade": "BOQUEIRÃO",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "NOVA FARMA",
    "cidade": "CARAÚBAS",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "FARMA ROCHAS",
    "cidade": "CARAÚBAS",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "FARMÁCIA VIRGEM DOS POBRES",
    "cidade": "CUBATI",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "BIO FARMA",
    "cidade": "CUITÉ",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "FARMÁCIA DIA E NOITE",
    "cidade": "CUITÉ",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "CANDIDO",
    "cidade": "FAGUNDES",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "NOSSA FARMA",
    "cidade": "GUARABIRA",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "FARMA CENTER",
    "cidade": "GURINHÉM",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "CANTALICE",
    "cidade": "GURJÃO",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "I FARMA",
    "cidade": "GURJÃO",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "DROGA VIDA",
    "cidade": "JAÇANÃ",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "CENTRAL FARMA",
    "cidade": "JURIPIRANGA",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "TODO DIA",
    "cidade": "LAGOA SECA",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "DROGARIA DO POVO",
    "cidade": "MASSARANDUBA",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "DIA E NOITE",
    "cidade": "MONTEIRO",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "HIPERFARMA BOM SUCESSO",
    "cidade": "PATOS",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "GILFARMA",
    "cidade": "POCINHOS",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "CENTER PHARMA",
    "cidade": "QUEIMADAS",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "SANTA LUZIA",
    "cidade": "SANTA LUZIA",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "BOMFIM",
    "cidade": "SÃO JOÃO DO CARIRI",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "IDEAL",
    "cidade": "SÃO JOÃO DO CARIRI",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "DROGARIA SOLANENSE",
    "cidade": "SOLÂNEA",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "GILFARMA",
    "cidade": "SOLEDADE",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "SOARES",
    "cidade": "SUMÉ",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "SANTA CLARA",
    "cidade": "SUMÉ",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "CAVALCANTE DE QUEIROZ",
    "cidade": "TAPEROÁ",
    "especialidade": "FARMÁCIA",
    "contato": ""
  },
  {
    "localidade": "PHOMULART",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "FARMÁCIA DE MANIPULAÇÃO",
    "contato": "83-33410693"
  },
  {
    "localidade": "PHARMA FACE",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "FARMÁCIA DE MANIPULAÇÃO",
    "contato": "83-33222050"
  },
  {
    "localidade": "PHYTOFÁRMACO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "FARMÁCIA DE MANIPULAÇÃO",
    "contato": "83-33215557"
  },
  {
    "localidade": "ROVAL",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "FARMÁCIA DE MANIPULAÇÃO",
    "contato": "83-30662222"
  },
  {
    "localidade": "UNNA DERME",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "FARMÁCIA DE MANIPULAÇÃO",
    "contato": "83-32012001"
  },
  {
    "localidade": "REDE MED",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "FARMÁCIA DE MANIPULAÇÃO",
    "contato": "83-30661600"
  },
  {
    "localidade": "PHARMA PELE",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "FARMÁCIA DE MANIPULAÇÃO",
    "contato": "83-33225477"
  },
  {
    "localidade": "CENTRO MÉDICO VETERINÁRIO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "FARMÁCIA VETERINÁRIA",
    "contato": "83-981891263"
  },
  {
    "localidade": "INGÁ",
    "cidade": "",
    "especialidade": "FILIAL PLAFAM",
    "contato": ""
  },
  {
    "localidade": "ITATUBA",
    "cidade": "",
    "especialidade": "FILIAL PLAFAM",
    "contato": ""
  },
  {
    "localidade": "REMIGIO",
    "cidade": "",
    "especialidade": "FILIAL PLAFAM",
    "contato": ""
  },
  {
    "localidade": "SÃO JOSÉ DOS CORDEIROS",
    "cidade": "",
    "especialidade": "FILIAL PLAFAM",
    "contato": ""
  },
  {
    "localidade": "SERRA BRANCA",
    "cidade": "",
    "especialidade": "FILIAL PLAFAM",
    "contato": ""
  },
  {
    "localidade": "JUAREZ TÁVORA",
    "cidade": "",
    "especialidade": "FILIAL PLAFAM",
    "contato": ""
  },
  {
    "localidade": "AREIA",
    "cidade": "",
    "especialidade": "FILIAL PLAFAM",
    "contato": ""
  },
  {
    "localidade": "BOQUEIRÃO",
    "cidade": "",
    "especialidade": "FILIAL PLAFAM",
    "contato": ""
  },
  {
    "localidade": "BARRA DE SANTA ROSA",
    "cidade": "",
    "especialidade": "FILIAL PLAFAM",
    "contato": ""
  },
  {
    "localidade": "CUITÉ",
    "cidade": "",
    "especialidade": "FILIAL PLAFAM",
    "contato": ""
  },
  {
    "localidade": "NOVA FLORESTA",
    "cidade": "",
    "especialidade": "FILIAL PLAFAM",
    "contato": ""
  },
  {
    "localidade": "MOGEIRO",
    "cidade": "",
    "especialidade": "FILIAL PLAFAM",
    "contato": ""
  },
  {
    "localidade": "DAMIÃO",
    "cidade": "",
    "especialidade": "FILIAL PLAFAM",
    "contato": ""
  },
  {
    "localidade": "ALGODÃO DE JANDAÍRA",
    "cidade": "",
    "especialidade": "FILIAL PLAFAM",
    "contato": ""
  },
  {
    "localidade": "PRONTO SOCORRO DE FRATURAS",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "FISIOTERAPIA",
    "contato": "83-33414979"
  },
  {
    "localidade": "CECORE CLINICA DE FRATURAS",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "FISIOTERAPIA",
    "contato": "83-33210423"
  },
  {
    "localidade": "ESPAÇO ESSENCE",
    "cidade": "ALAGOA GRANDE",
    "especialidade": "FISIOTERAPIA",
    "contato": ""
  },
  {
    "localidade": "DRA. NEIDA ROMERO",
    "cidade": "AREIA",
    "especialidade": "FISIOTERAPIA",
    "contato": ""
  },
  {
    "localidade": "CONSULTÓRIO DE FISIOTERAPIA E OSTEOPATIA",
    "cidade": "CACIMBA DE DENTRO",
    "especialidade": "FISIOTERAPIA",
    "contato": ""
  },
  {
    "localidade": "FISIOVITTA",
    "cidade": "CARAÚBAS",
    "especialidade": "FISIOTERAPIA",
    "contato": ""
  },
  {
    "localidade": "DR. THIAGO IGOR MEDEIROS DO NASCIMENTO",
    "cidade": "GURINHÉM",
    "especialidade": "FISIOTERAPIA",
    "contato": ""
  },
  {
    "localidade": "DRA. RUTHE SOUTO",
    "cidade": "ITATUBA",
    "especialidade": "FISIOTERAPIA",
    "contato": ""
  },
  {
    "localidade": "FISIOCLIN",
    "cidade": "QUEIMADAS",
    "especialidade": "FISIOTERAPIA",
    "contato": ""
  },
  {
    "localidade": "ESPAÇO FISIO",
    "cidade": "QUEIMADAS",
    "especialidade": "FISIOTERAPIA",
    "contato": ""
  },
  {
    "localidade": "CONSULTÓRIO DE FISIOTERAPIA E OSTEOPATIA",
    "cidade": "SOLÂNEA",
    "especialidade": "FISIOTERAPIA",
    "contato": ""
  },
  {
    "localidade": "MASTERFISIO",
    "cidade": "SOLEDADE",
    "especialidade": "FISIOTERAPIA",
    "contato": ""
  },
  {
    "localidade": "DRA. GABRILL SOUZA",
    "cidade": "SERRA BRANCA",
    "especialidade": "FISIOTERAPIA",
    "contato": ""
  },
  {
    "localidade": "DRA. KATHARINE SOUZA OLIVEIRA",
    "cidade": "SERRA BRANCA",
    "especialidade": "FISIOTERAPIA",
    "contato": ""
  },
  {
    "localidade": "FLORES BELAS",
    "cidade": "CUITÉ",
    "especialidade": "FLORICULTURA",
    "contato": ""
  },
  {
    "localidade": "CLINICA SANTA CLARA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "FONOAUDIOLOGIA",
    "contato": "83-31134344"
  },
  {
    "localidade": "CLINICA DR. PEDRO SAULO",
    "cidade": "QUEIMADAS",
    "especialidade": "FONOAUDIOLOGIA",
    "contato": "83-31134344"
  },
  {
    "localidade": "CLINICA ORTOCLIN",
    "cidade": "ESPERANÇA",
    "especialidade": "FONOAUDIOLOGIA",
    "contato": "83-31134344"
  },
  {
    "localidade": "DRA. LAVINIA BRANDÃO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "FONOAUDIOLOGIA",
    "contato": "83-33411183"
  },
  {
    "localidade": "CENTRO MÉDICO JPBBRANDÃO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "FONOAUDIOLOGIA",
    "contato": "83-33411183"
  },
  {
    "localidade": "ENDOGASTRO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "GASTROENTEROLOGIA",
    "contato": "83-33226122"
  },
  {
    "localidade": "PROGASTRO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "GASTROENTEROLOGIA",
    "contato": "83-33416784"
  },
  {
    "localidade": "DR. JOSÉ ADAINO BARROS",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "GASTROENTEROLOGIA",
    "contato": "83-33416784"
  },
  {
    "localidade": "DR. HAMILTON ARAGÃO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "GASTROENTEROLOGIA",
    "contato": "83-30555090"
  },
  {
    "localidade": "DR. RODOLFO SABINO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "GASTROENTEROLOGIA",
    "contato": "83-21578983"
  },
  {
    "localidade": "DRA. ARIADNE ALVES CAMPOS",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "GASTROENTEROLOGIA",
    "contato": "83-30555090"
  },
  {
    "localidade": "DRA. ANGELA MARIA TARGINO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "GASTROENTEROLOGIA",
    "contato": "83-33415419"
  },
  {
    "localidade": "DRA. MARIA GORETE PINTO DE OLIVEIRA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "GASTROENTEROLOGIA",
    "contato": "83-33418717"
  },
  {
    "localidade": "IFCG",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "GASTROENTEROLOGIA",
    "contato": "83-33416996"
  },
  {
    "localidade": "DR. JOSÉ ADAINO BARROS DA NOBREGA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "GASTROENTEROLOGIA",
    "contato": "83-33416784"
  },
  {
    "localidade": "DRA. DENISE AMORIM",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "GASTROPEDIATRIA",
    "contato": "83-982280153"
  },
  {
    "localidade": "DR. JOÃO BORGES VIRGULINO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "GERIATRIA",
    "contato": "83-30887635"
  },
  {
    "localidade": "ORTOCENTER JK",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "GINECOLOGIA",
    "contato": "83-21578983"
  },
  {
    "localidade": "DRA. PAUMARISA VIEIRA DE AZEVEDO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "GINECOLOGIA",
    "contato": "83-988013300"
  },
  {
    "localidade": "DRA. CLAÚDIA BIANCA DE SOUZA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "GINECOLOGIA",
    "contato": "83-987104829"
  },
  {
    "localidade": "DR. SEVERINO TAVARES DA SILVA",
    "cidade": "FAGUNDES",
    "especialidade": "GINECOLOGIA",
    "contato": ""
  },
  {
    "localidade": "DRA. MARIA DA PENHA PEREIRA DE LIMA",
    "cidade": "GUARABIRA",
    "especialidade": "GINECOLOGIA E OBSTETRICIA",
    "contato": ""
  },
  {
    "localidade": "DRA. MARIA DA PENHA PEREIRA DE LIMA",
    "cidade": "JOÃO PESSOA",
    "especialidade": "GINECOLOGIA E OBSTETRICIA",
    "contato": ""
  },
  {
    "localidade": "DRA. DANIELLE LIMA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "HARMONIZAÇÃO FACIAL",
    "contato": "83-21578983"
  },
  {
    "localidade": "DR. YGOR BARACUY",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "HEMATOLOGISTA",
    "contato": "83-33416803"
  },
  {
    "localidade": "DRA. HAYSA BARACUY",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "HEMATOLOGISTA",
    "contato": "83-33416803"
  },
  {
    "localidade": "INSTITUTO DO FIGADO DE CAMPINA GRANDE",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "HEPATOLOGIA",
    "contato": "83-33416996"
  },
  {
    "localidade": "HOSPITAL JOÃO XXIII",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "HOSPITAL",
    "contato": "83-21022323"
  },
  {
    "localidade": "HOSPITAL HELP",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "HOSPITAL HELP",
    "contato": "83-30639960"
  },
  {
    "localidade": "DRA. SÔNIA M. BARBOSA DE SOUZA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "INFECTOLOGISTA",
    "contato": "83-30990322"
  },
  {
    "localidade": "IMPRIMA INFORMÁTICA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "INFORMÁTICA",
    "contato": "83-33414532"
  },
  {
    "localidade": "F. DINIZ",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "LABORATÓRIO",
    "contato": "83-33157373"
  },
  {
    "localidade": "BIOANÁLISE",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "LABORATÓRIO",
    "contato": "83-33216117"
  },
  {
    "localidade": "DIAGNOSE",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "LABORATÓRIO",
    "contato": "83-33420076"
  },
  {
    "localidade": "CECAC",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "LABORATÓRIO",
    "contato": "83-21533214"
  },
  {
    "localidade": "CEMED",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "LABORATÓRIO",
    "contato": "83-30995615"
  },
  {
    "localidade": "CENTRO DE DIAGNÓSTICO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "LABORATÓRIO",
    "contato": "83-33414324"
  },
  {
    "localidade": "UCD",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "LABORATÓRIO",
    "contato": "83-21016900"
  },
  {
    "localidade": "DR. JOSÉ INÁCIO DA SILVA",
    "cidade": "ALAGOA GRANDE",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "BIOCLIM",
    "cidade": "ALAGOA GRANDE",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "PROMÉDICA",
    "cidade": "ALAGOA GRANDE",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "ANACLIN",
    "cidade": "ALAGOA NOVA",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "BIOCLIM",
    "cidade": "ALAGOA NOVA",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "MULTIANÁLISE",
    "cidade": "ARARA",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "DR. LEONARDO ALBERTO",
    "cidade": "AREIA",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "DR. TAFELI CABRAL DE VASCONCELOS",
    "cidade": "AREIA",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "BIOCLIM",
    "cidade": "AREIA",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "BIO DIAGNÓSTICO",
    "cidade": "AROEIRAS",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "AF LAB",
    "cidade": "BARRA DE SÃO MIGUEL",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "BIOCLIM",
    "cidade": "CACIMBA DE DENTRO",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "SANTA TEREZA",
    "cidade": "DESTERRO",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "HEMOCLINICA",
    "cidade": "ESPERANÇA",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "LABOTEST CLIMOL",
    "cidade": "ESPERANÇA",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "F. DINIZ",
    "cidade": "ESPERANÇA",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "ULTRALAB",
    "cidade": "GURJÃO",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "ADOLF LINS",
    "cidade": "JAÇANÃ",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "DR. ÍTALO WEIBER",
    "cidade": "ITATUBA",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "EXAMES EM GERAL",
    "cidade": "LAGOA DE ROÇA",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "ANÁLISES CLÍNICAS",
    "cidade": "LAGOA SECA",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "EXAMES EM GERAL",
    "cidade": "MASSARANDUBA",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "CLINILAB",
    "cidade": "MONTADAS",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "DR. ALCIDES CANDEIA",
    "cidade": "PATOS",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "PRO SAÚDE",
    "cidade": "PICUÍ",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "R-LABLABORATÓRIO",
    "cidade": "POCINHOS",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "LABOVIT",
    "cidade": "PUXINANÃ",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "T-SAÚDE",
    "cidade": "QUEIMADAS",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "LABEXAMES",
    "cidade": "QUEIMADAS",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "LABFESPE",
    "cidade": "SALGADO DE SÃO FELIX",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "ADOLF",
    "cidade": "SANTA CRUZ - RN",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "LAC - LABORATÓRIO",
    "cidade": "SANTA LUZIA",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "LUCRECIA MONTEIRO",
    "cidade": "SOLÂNEA",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "MARIA DA PENHA",
    "cidade": "SOLÂNEA",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "BIOCLIM",
    "cidade": "SOLÂNEA",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "PRÓ SAÚDE",
    "cidade": "SOLEDADE",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "SANTA CLARA",
    "cidade": "SERRA BRANCA",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "FERNANDES",
    "cidade": "SERRA BRANCA",
    "especialidade": "LABORATÓRIO",
    "contato": ""
  },
  {
    "localidade": "CLINICA SANTA CLARA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "MEDICINA DO SONO",
    "contato": "83-31134344"
  },
  {
    "localidade": "CLINICA DR. PEDRO SAULO",
    "cidade": "QUEIMADAS",
    "especialidade": "MEDICINA DO SONO",
    "contato": "83-31134344"
  },
  {
    "localidade": "CLINICA ORTOCLIN",
    "cidade": "ESPERANÇA",
    "especialidade": "MEDICINA DO SONO",
    "contato": "83-31134344"
  },
  {
    "localidade": "DRA. KARINA SOUSA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CLÍNICO GERAL",
    "contato": "83-21578983"
  },
  {
    "localidade": "DR. GIOVANNI TAVARES",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "CLÍNICO GERAL",
    "contato": "83-21578983"
  },
  {
    "localidade": "DR. ERICSSON ALBUQUERQUE",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "MÉDICO DO ESPORTE",
    "contato": "83-33414666"
  },
  {
    "localidade": "DRA. VALÉRIA BRANDÃO MARQUES",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "MÉDICO DO SONO",
    "contato": "83-33411183"
  },
  {
    "localidade": "DRA. RACHEL DE CASTRO LOUREIRO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "METABOLOGIA",
    "contato": "83-33224342"
  },
  {
    "localidade": "AURI MODAS",
    "cidade": "AREIA",
    "especialidade": "MODA E CONFECÇÕES",
    "contato": ""
  },
  {
    "localidade": "ABSOLUTA MODAS",
    "cidade": "SERRA BRANCA",
    "especialidade": "MODA E CONFECÇÕES",
    "contato": ""
  },
  {
    "localidade": "DRA. ANA MARIA BELO MANGUEIRA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "NEFROLOGIA",
    "contato": "83-33226512"
  },
  {
    "localidade": "DRA. MARIA APARECIDA FIRMINO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "NEFROLOGIA",
    "contato": "83-33412745"
  },
  {
    "localidade": "ORTOCENTER JK",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "NEUROCIRURGIA",
    "contato": "83-21578983"
  },
  {
    "localidade": "DR. DANIEL DEAN",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "NEUROCIRURGIÃO",
    "contato": "83-33222354"
  },
  {
    "localidade": "ORTOCENTER JK",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "NEUROLOGIA",
    "contato": "83-21578983"
  },
  {
    "localidade": "NEURO CENTER",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "NEUROLOGIA",
    "contato": "83-33411588"
  },
  {
    "localidade": "DR. DANIEL DEAN",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "NEUROLOGIA",
    "contato": "83-33222354"
  },
  {
    "localidade": "ESPAÇO SIGMUNDO FREUD",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "NUTRICIONISTA",
    "contato": "83-988124032"
  },
  {
    "localidade": "DRA. KATIANE PATRICIO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "NUTRICIONISTA",
    "contato": "83-21578983"
  },
  {
    "localidade": "DRA. KÁTIA DINIZ",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "NUTRICIONISTA",
    "contato": "83-33215038"
  },
  {
    "localidade": "DR. MARIO DE OLIVEIRA FILHO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "NUTRICIONISTA",
    "contato": "83-33418717"
  },
  {
    "localidade": "DR. HAMILTON ARAGÃO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "NUTRICIONISTA",
    "contato": "83-30555090"
  },
  {
    "localidade": "DR. RODOLFO SABINO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "NUTRICIONISTA",
    "contato": "83-21578983"
  },
  {
    "localidade": "DRA. CLAÚDIA BIANCA DE SOUZA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "NUTRICIONISTA",
    "contato": "83-987104829"
  },
  {
    "localidade": "DRA. MARIA GORETE PINTO DE OLIVEIRA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "NUTRICIONISTA",
    "contato": "83-33418717"
  },
  {
    "localidade": "DRA. AMÉLIA DE ALMEIDA MACEDO",
    "cidade": "ALAGOA GRANDE",
    "especialidade": "NUTRICIONISTA",
    "contato": ""
  },
  {
    "localidade": "DRA. SAMANTA DE FARIAS ARAÚJO",
    "cidade": "ALAGOA NOVA",
    "especialidade": "NUTRICIONISTA",
    "contato": ""
  },
  {
    "localidade": "DRA. ROSANY GOUVEIA",
    "cidade": "SERRA BRANCA",
    "especialidade": "NUTRICIONISTA",
    "contato": ""
  },
  {
    "localidade": "DR. J. EDMILSON LEITE JUNIOR",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "NUTROLOGIA",
    "contato": "83-30555090"
  },
  {
    "localidade": "ORTOCENTER JK",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "OBSTETRICIA",
    "contato": "83-21578983"
  },
  {
    "localidade": "DRA. MARIA GORETE PINTO DE OLIVEIRA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "OBSTETRICIA",
    "contato": "83-33418717"
  },
  {
    "localidade": "DENTAL LIDER",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ODONTOLOGIA",
    "contato": "83-33415196"
  },
  {
    "localidade": "M CLINIC FACE",
    "cidade": "INGÁ",
    "especialidade": "ODONTOLOGIA",
    "contato": "83-994130332"
  },
  {
    "localidade": "DR. GUSTAVO PERAZZO",
    "cidade": "ALAGOA GRANDE",
    "especialidade": "ODONTOLOGIA",
    "contato": ""
  },
  {
    "localidade": "DR. JOSÉ DE ARIMATEIA MONTEIRO",
    "cidade": "ALAGOA GRANDE",
    "especialidade": "ODONTOLOGIA",
    "contato": ""
  },
  {
    "localidade": "ODONTO CENTER",
    "cidade": "ALAGOA NOVA",
    "especialidade": "ODONTOLOGIA",
    "contato": ""
  },
  {
    "localidade": "CONSULTÓRIO ODONTOLÓGICO",
    "cidade": "ARARA",
    "especialidade": "ODONTOLOGIA",
    "contato": ""
  },
  {
    "localidade": "CARVALHO ODONTOLOGIA",
    "cidade": "AREIA",
    "especialidade": "ODONTOLOGIA",
    "contato": ""
  },
  {
    "localidade": "DRA MAYSA MAIA DE ALBUQUERQUE MARIZ DIAS",
    "cidade": "AREIA",
    "especialidade": "ODONTOLOGIA",
    "contato": ""
  },
  {
    "localidade": "DRA. EDNA GUEDES PEQUENO",
    "cidade": "AREIA",
    "especialidade": "ODONTOLOGIA",
    "contato": ""
  },
  {
    "localidade": "PERSONALI",
    "cidade": "AREIA",
    "especialidade": "ODONTOLOGIA",
    "contato": ""
  },
  {
    "localidade": "MINERAL ODONTO",
    "cidade": "AROEIRAS",
    "especialidade": "ODONTOLOGIA",
    "contato": ""
  },
  {
    "localidade": "DENTAL FOOK",
    "cidade": "AROEIRAS",
    "especialidade": "ODONTOLOGIA",
    "contato": ""
  },
  {
    "localidade": "DRA. CRISTIANA VALESKA M SALES",
    "cidade": "AROEIRAS",
    "especialidade": "ODONTOLOGIA",
    "contato": ""
  },
  {
    "localidade": "DRA. GENECI A DA SILVA",
    "cidade": "CARAÚBAS",
    "especialidade": "ODONTOLOGIA",
    "contato": ""
  },
  {
    "localidade": "DRA. ELÍSIA MARIA F PALMEIRA",
    "cidade": "CARAÚBAS",
    "especialidade": "ODONTOLOGIA",
    "contato": ""
  },
  {
    "localidade": "DELÍNEA",
    "cidade": "EQUADOR - RN",
    "especialidade": "ODONTOLOGIA",
    "contato": ""
  },
  {
    "localidade": "MEGA DENTAL",
    "cidade": "ESPERANÇA",
    "especialidade": "ODONTOLOGIA",
    "contato": ""
  },
  {
    "localidade": "SORRIDENTE",
    "cidade": "GURINHÉM",
    "especialidade": "ODONTOLOGIA",
    "contato": ""
  },
  {
    "localidade": "DRA. GENECI A DA SILVA",
    "cidade": "JARDIM DO SERIDÓ",
    "especialidade": "ODONTOLOGIA",
    "contato": ""
  },
  {
    "localidade": "CONSULTÓRIO NOSSA SENHORA DE FÁTIMA",
    "cidade": "LIVRAMENTO",
    "especialidade": "ODONTOLOGIA",
    "contato": ""
  },
  {
    "localidade": "SÓ RISO",
    "cidade": "MONTEIRO",
    "especialidade": "ODONTOLOGIA",
    "contato": ""
  },
  {
    "localidade": "CENTRO DE ODONTOLOGIA INTEGRADA",
    "cidade": "NOVA FLORESTA",
    "especialidade": "ODONTOLOGIA",
    "contato": ""
  },
  {
    "localidade": "DRA. GENECI A DA SILVA",
    "cidade": "PARELHAS",
    "especialidade": "ODONTOLOGIA",
    "contato": ""
  },
  {
    "localidade": "DR. BRUNO VARGAS OLIVEIRA",
    "cidade": "QUEIMADAS",
    "especialidade": "ODONTOLOGIA",
    "contato": ""
  },
  {
    "localidade": "DENTAL MIX ODONTOLOGIA",
    "cidade": "QUEIMADAS",
    "especialidade": "ODONTOLOGIA",
    "contato": ""
  },
  {
    "localidade": "CONSULTÓRIO ODONTOLÓGICO",
    "cidade": "SOLÂNEA",
    "especialidade": "ODONTOLOGIA",
    "contato": ""
  },
  {
    "localidade": "NOSSA SENHORA DE FÁTIMA",
    "cidade": "SUMÉ",
    "especialidade": "ODONTOLOGIA",
    "contato": ""
  },
  {
    "localidade": "UNIDENTAL",
    "cidade": "REMIGIO",
    "especialidade": "ODONTOLOGIA",
    "contato": ""
  },
  {
    "localidade": "NOSSA SENHORA DE FÁTIMA",
    "cidade": "SERRA BRANCA",
    "especialidade": "ODONTOLOGIA",
    "contato": ""
  },
  {
    "localidade": "SPA DRA. DORA",
    "cidade": "SERRA BRANCA",
    "especialidade": "ODONTOLOGIA",
    "contato": ""
  },
  {
    "localidade": "ELETRO PETRO MOTOS",
    "cidade": "SERRA BRANCA",
    "especialidade": "OFICINA",
    "contato": ""
  },
  {
    "localidade": "DRA. JOSENI DOS SANTOS WANDERLEY",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "OFTALMOLOGIA",
    "contato": "83-21544551"
  },
  {
    "localidade": "IOL - DR. EDUARDO CAMPOS",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "OFTALMOLOGIA",
    "contato": "83-30771777"
  },
  {
    "localidade": "DRA. WEDNA TORRES ANDRADE DE NÓBREGA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "OFTALMOLOGIA",
    "contato": "83-33227845"
  },
  {
    "localidade": "PROJETO VISÃO",
    "cidade": "SÃO JOÃO DO CARIRI",
    "especialidade": "OFTALMOLOGIA",
    "contato": ""
  },
  {
    "localidade": "DENTAL LIDER",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTODONTIA",
    "contato": "83-33224710"
  },
  {
    "localidade": "DRA. MICHELE GUEDES",
    "cidade": "AREIA",
    "especialidade": "ORTODONTIA",
    "contato": ""
  },
  {
    "localidade": "ORTOCENTER JK",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA",
    "contato": "83-21578983"
  },
  {
    "localidade": "PRONTO SOCORRO DE FRATURAS",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA",
    "contato": "83-33414979"
  },
  {
    "localidade": "DR. BRUNO BRILHANTE",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-33216497"
  },
  {
    "localidade": "DR. ANDREY WANDERLEY",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-33414979"
  },
  {
    "localidade": "DRA. TATIANA DE OLIVEIRA MEDEIROS",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-33414666"
  },
  {
    "localidade": "CTO - CLINICA DE TRAUMA E ORTOPEDIA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-33412560"
  },
  {
    "localidade": "DR. NEUBI FERNANDES",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-33414979"
  },
  {
    "localidade": "DR. BISMARCK FERNANDES",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-33414979"
  },
  {
    "localidade": "DR. ANDRÉ RIBEIRO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-33414979"
  },
  {
    "localidade": "DR. FELIPE FERNANDES",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-33414979"
  },
  {
    "localidade": "CECORE CLINICA DE FRATURA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-33210423"
  },
  {
    "localidade": "DRA. ANA PAULA CARDOSO DE MEDEIROS",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-33414979"
  },
  {
    "localidade": "DR. ARISTOTALES NETO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-33414666"
  },
  {
    "localidade": "DR. CARLOS CANDIDO ROCHA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-33414666"
  },
  {
    "localidade": "DR. CARLOS CANDIDO ROCHA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-33414666"
  },
  {
    "localidade": "DR. FRANCISCO VIEIRA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-33414666"
  },
  {
    "localidade": "DRA. PAULA C. MEDEIROS",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-33414666"
  },
  {
    "localidade": "DR. RODOLFO COIMBRA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-33414666"
  },
  {
    "localidade": "DR. EDUARDO OTÁVIO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-33414979"
  },
  {
    "localidade": "DR. MARCIO TADEU RODRIGUES",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-33210423"
  },
  {
    "localidade": "DR. WAGNER LUIZ DE ARAÚJO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-33210423"
  },
  {
    "localidade": "DR. LEONARDO CÉZAR DE OLIVEIRA SOUZA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-32011918"
  },
  {
    "localidade": "DR. RODRIGO PORTO AMORIM GUEDES",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-33414666"
  },
  {
    "localidade": "CLINICA MAIOR",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-33418700"
  },
  {
    "localidade": "DR. CRISMARCOS R. SILVA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-33414666"
  },
  {
    "localidade": "DRA. JULIANA PICANTI",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-33414666"
  },
  {
    "localidade": "DR. LUIZ JUVÊNCIO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-33414666"
  },
  {
    "localidade": "DR. RICARDO AMORIM GUEDES FILHO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-21578983"
  },
  {
    "localidade": "ORTOTRAUMA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-33414666"
  },
  {
    "localidade": "DR. LUCIANO GUEDES",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-33412566"
  },
  {
    "localidade": "DR. RODRIGO PORTO AMORIM GUEDES",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-21578983"
  },
  {
    "localidade": "DR. WAERSON JOSÉ",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-21578983"
  },
  {
    "localidade": "DR. WAGNER LUIZ DE ARAÚJO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-21578983"
  },
  {
    "localidade": "DR. MARCELIO H. SILVA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-33414666"
  },
  {
    "localidade": "DR. GIOVANINI CÉSAR FIGUEIREDO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-33414666"
  },
  {
    "localidade": "CLINICA SÃO CAMILO DIAGNÓSTICO SALA 03 E 06",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-33227854"
  },
  {
    "localidade": "WAGNER LUIZ DE ARAÚJO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": "83-33210423"
  },
  {
    "localidade": "CLINICA DE FRATURAS E DOENÇAS ÓSSEAS",
    "cidade": "PATOS",
    "especialidade": "ORTOPEDIA E TRAUMATOLOGIA",
    "contato": ""
  },
  {
    "localidade": "DRA. JULIANA PICANTI",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ORTOPEDIA INFANTIL",
    "contato": "83-33414666"
  },
  {
    "localidade": "ÓTICA BRENNA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ÓTICAS",
    "contato": "83-21541217"
  },
  {
    "localidade": "ÓTICA GÊNIUS",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ÓTICAS",
    "contato": "83-33221078"
  },
  {
    "localidade": "GRACY LUX ÓTICA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ÓTICAS",
    "contato": "83-988002620"
  },
  {
    "localidade": "TAMAR",
    "cidade": "ALAGOA NOVA",
    "especialidade": "ÓTICAS",
    "contato": ""
  },
  {
    "localidade": "VITÓRIA",
    "cidade": "ALAGOA NOVA",
    "especialidade": "ÓTICAS",
    "contato": ""
  },
  {
    "localidade": "ANNY",
    "cidade": "ALGODÃO DE JANDAÍRA",
    "especialidade": "ÓTICAS",
    "contato": ""
  },
  {
    "localidade": "MIRNA",
    "cidade": "ARARA",
    "especialidade": "ÓTICAS",
    "contato": ""
  },
  {
    "localidade": "ÓCULOS E CIA",
    "cidade": "AREIA",
    "especialidade": "ÓTICAS",
    "contato": ""
  },
  {
    "localidade": "ÓTICA JULIANA",
    "cidade": "AROEIRAS",
    "especialidade": "ÓTICAS",
    "contato": ""
  },
  {
    "localidade": "VISUAL",
    "cidade": "BOQUEIRÃO",
    "especialidade": "ÓTICAS",
    "contato": ""
  },
  {
    "localidade": "RYCASSIA ÓTICA",
    "cidade": "ESPERANÇA",
    "especialidade": "ÓTICAS",
    "contato": ""
  },
  {
    "localidade": "ÓTICA GOMES",
    "cidade": "ESPERANÇA",
    "especialidade": "ÓTICAS",
    "contato": ""
  },
  {
    "localidade": "DINIZ",
    "cidade": "ESPERANÇA",
    "especialidade": "ÓTICAS",
    "contato": ""
  },
  {
    "localidade": "MIRNA",
    "cidade": "ESPERANÇA",
    "especialidade": "ÓTICAS",
    "contato": ""
  },
  {
    "localidade": "OFTALMOCENTRO",
    "cidade": "ESPERANÇA",
    "especialidade": "ÓTICAS",
    "contato": ""
  },
  {
    "localidade": "SÃO SEBASTIÃO",
    "cidade": "GURJÃO",
    "especialidade": "ÓTICAS",
    "contato": ""
  },
  {
    "localidade": "NOSSA SENHORA DAS DORES",
    "cidade": "MONTEIRO",
    "especialidade": "ÓTICAS",
    "contato": ""
  },
  {
    "localidade": "VISÃO",
    "cidade": "MONTEIRO",
    "especialidade": "ÓTICAS",
    "contato": ""
  },
  {
    "localidade": "EVANEIDE ÓTICA",
    "cidade": "NOVA FLORESTA",
    "especialidade": "ÓTICAS",
    "contato": ""
  },
  {
    "localidade": "MARINA",
    "cidade": "NOVA FLORESTA",
    "especialidade": "ÓTICAS",
    "contato": ""
  },
  {
    "localidade": "LÚCIA ÓTICA",
    "cidade": "PATOS",
    "especialidade": "ÓTICAS",
    "contato": ""
  },
  {
    "localidade": "SÃO PAULO",
    "cidade": "POCINHOS",
    "especialidade": "ÓTICAS",
    "contato": ""
  },
  {
    "localidade": "AMÉRICA",
    "cidade": "QUEIMADAS",
    "especialidade": "ÓTICAS",
    "contato": ""
  },
  {
    "localidade": "BRUNO ÓTICAS",
    "cidade": "QUEIMADAS",
    "especialidade": "ÓTICAS",
    "contato": ""
  },
  {
    "localidade": "DINIZ",
    "cidade": "QUEIMADAS",
    "especialidade": "ÓTICAS",
    "contato": ""
  },
  {
    "localidade": "JULIANA",
    "cidade": "QUEIMADAS",
    "especialidade": "ÓTICAS",
    "contato": ""
  },
  {
    "localidade": "SANTA LUZIA",
    "cidade": "QUEIMADAS",
    "especialidade": "ÓTICAS",
    "contato": ""
  },
  {
    "localidade": "ROMÃO",
    "cidade": "SÃO JOÃO DO CARIRI",
    "especialidade": "ÓTICAS",
    "contato": ""
  },
  {
    "localidade": "NOSSA SENHORA DOS MILAGRES",
    "cidade": "SÃO JOÃO DO CARIRI",
    "especialidade": "ÓTICAS",
    "contato": ""
  },
  {
    "localidade": "NOSSA SENHORA DA CONCEIÇÃO",
    "cidade": "SERRA BRANCA",
    "especialidade": "ÓTICAS",
    "contato": ""
  },
  {
    "localidade": "CLINICA SANTA CLARA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "OTORRINO",
    "contato": "83-987530378"
  },
  {
    "localidade": "CLINICA DR. PEDRO SAULO",
    "cidade": "QUEIMADAS",
    "especialidade": "OTORRINOLARINGOLOGISTA",
    "contato": "83-31134344"
  },
  {
    "localidade": "CLINICA ORTOCLIN",
    "cidade": "ESPERANÇA",
    "especialidade": "OTORRINOLARINGOLOGISTA",
    "contato": "83-31134344"
  },
  {
    "localidade": "DR. ROGÉRIO JOSÉ BRANDÃO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "OTORRINOLARINGOLOGISTA",
    "contato": "83-33411183"
  },
  {
    "localidade": "DRA. VALÉRIA BRANDÃO MARQUES",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "OTORRINOLARINGOLOGISTA",
    "contato": "83-33411183"
  },
  {
    "localidade": "DRA. DENISE AMORIM",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "PEDIATRIA",
    "contato": "83-30664524"
  },
  {
    "localidade": "DRA. ANA MARIA BELO MANGUEIRA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "PEDIATRIA",
    "contato": "83-33226512"
  },
  {
    "localidade": "NEURO CENTER",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "PEDIATRIA",
    "contato": "83-33411588"
  },
  {
    "localidade": "TERAPIAS INTEGRADAS ESPAÇO VOCÊ",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "PILATES",
    "contato": "83-981474165"
  },
  {
    "localidade": "DR. JUAREZ RITTER",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "PNEUMOLOGISTA",
    "contato": "83-33222354"
  },
  {
    "localidade": "DR. RIVALDO FILHO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "PNEUMOLOGISTA",
    "contato": "83-33222354"
  },
  {
    "localidade": "DRA. MARIA ALZIRA DE OLIVEIRA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "PNEUMOLOGISTA",
    "contato": "83-33411588"
  },
  {
    "localidade": "PREVSAÚDE",
    "cidade": "JOÃO PESSOA",
    "especialidade": "POLICLINICA",
    "contato": ""
  },
  {
    "localidade": "CLINICA SÃO CAMILO DIAGNÓSTICO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "PROCTOLOGISTA",
    "contato": "83-33227854"
  },
  {
    "localidade": "DR. EVALDO DANTAS",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "PROCTOLOGISTA",
    "contato": "83-33227845"
  },
  {
    "localidade": "PSICOLOGA JACKVÂNIA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "PSICOLOGIA",
    "contato": "83-988433061"
  },
  {
    "localidade": "ESPAÇO DE PSICOLOGIA VIVAMENTE",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "PSICOLOGIA",
    "contato": "83-33224787"
  },
  {
    "localidade": "NAIAHARA GURJÃO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "PSICOLOGIA",
    "contato": "83-988359365"
  },
  {
    "localidade": "DRA. ALINE F DEMELO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "PSICOLOGIA",
    "contato": "83-988124032"
  },
  {
    "localidade": "SERENAMENTE",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "PSICOLOGIA",
    "contato": "83-988915458"
  },
  {
    "localidade": "DRA. MARIA SMITH PEREIRA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "PSICOLOGIA",
    "contato": "83-996076879"
  },
  {
    "localidade": "DRA. ZULEIDE SANTOS",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "PSICOLOGIA",
    "contato": "83-998034047"
  },
  {
    "localidade": "ESPAÇO SIGMUNDO FREUD",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "PSICOLOGIA",
    "contato": "83-988124032"
  },
  {
    "localidade": "DRA. ACACILEIDE CAMILO DINIZ",
    "cidade": "ALAGOA NOVA",
    "especialidade": "PSICOLOGIA",
    "contato": ""
  },
  {
    "localidade": "CLINICA DE PSICOLOGIA",
    "cidade": "SANTA LUZIA",
    "especialidade": "PSICOLOGIA",
    "contato": ""
  },
  {
    "localidade": "DRA. ANDERLANE MAIA R CORDEIRO",
    "cidade": "SOLÂNEA",
    "especialidade": "PSICOLOGIA",
    "contato": ""
  },
  {
    "localidade": "TERAPIAS INTEGRADAS ESPAÇO VOCÊ",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "PSICOLOGIA",
    "contato": "83-981474165"
  },
  {
    "localidade": "CLINICA DR. MAIA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "PSIQUIATRIA",
    "contato": "83-33413854"
  },
  {
    "localidade": "TERAPIAS INTEGRADAS ESPAÇO VOCÊ",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "QUIROPRAXIA",
    "contato": "83-981474165"
  },
  {
    "localidade": "CLINICA DR. WANDERLEY",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "RADIOLOGIA",
    "contato": "83-991355349"
  },
  {
    "localidade": "SER",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "RADIOLOGIA",
    "contato": "83-33411355"
  },
  {
    "localidade": "CLINIRAD",
    "cidade": "GUARABIRA",
    "especialidade": "RADIOLOGIA",
    "contato": ""
  },
  {
    "localidade": "SÃO JOSÉ",
    "cidade": "AROEIRAS",
    "especialidade": "RESTAURANTE",
    "contato": ""
  },
  {
    "localidade": "ORTOCENTER JK",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "REUMATOLOGIA",
    "contato": "83-21578983"
  },
  {
    "localidade": "DR. EDUARDO SÉRGIO RAMALHO",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "REUMATOLOGIA",
    "contato": "83-33221242"
  },
  {
    "localidade": "DRA. GERTUDRES ARIA MEDEIROS",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "REUMATOLOGIA",
    "contato": "83-33418700"
  },
  {
    "localidade": "DRA. KHAREN BEZERRA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "REUMATOLOGIA",
    "contato": "83-21578983"
  },
  {
    "localidade": "POINT DA BELEZA",
    "cidade": "BOQUEIRÃO",
    "especialidade": "SALÃO DE BELEZA",
    "contato": ""
  },
  {
    "localidade": "ESPAÇO ALZENIR DE BELEZA",
    "cidade": "MONTADAS",
    "especialidade": "SALÃO DE BELEZA",
    "contato": ""
  },
  {
    "localidade": "SINDICATO DOS TRABALHADORES RURAIS",
    "cidade": "ITATUBA",
    "especialidade": "SINDICATO",
    "contato": ""
  },
  {
    "localidade": "TECIDOS E CIA",
    "cidade": "QUEIMADAS",
    "especialidade": "TECIDOS",
    "contato": ""
  },
  {
    "localidade": "TERAPIAS INTEGRADAS ESPAÇO VOCÊ",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "TERAPIA GENERATIVA",
    "contato": "83-981474165"
  },
  {
    "localidade": "PRONTO SOCORRO DE FRATURAS",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "TRAUMATOLOGIA",
    "contato": "83-33414979"
  },
  {
    "localidade": "SPECTRO IMAGEM",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ULTRASSONOGRAFIA",
    "contato": "83-33213284"
  },
  {
    "localidade": "CLINICA MATERNA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "ULTRASSONOGRAFIA",
    "contato": "83-996860106"
  },
  {
    "localidade": "CECORE CLINICA DE FRATURAS",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "UROLOGIA",
    "contato": "83-33210423"
  },
  {
    "localidade": "DR. WENDYSON SOUZA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "UROLOGIA",
    "contato": "83-30990322"
  },
  {
    "localidade": "DR. FRANCISCO BURITI",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "UROLOGIA",
    "contato": "83-33210423"
  },
  {
    "localidade": "DR. EDMAR AMORIM BORBA",
    "cidade": "CAMPINA GRANDE",
    "especialidade": "UROLOGIA",
    "contato": "83-33224397"
  }
];

export const testimonyData = [
  {
    "id": 1,
    "name": "CARLOS MENDES",
    "location": "Campina Grande",
    "profileImage": oldMan,
    "description": "Quando perdi meu pai, a Plafam foi um amparo essencial. Em um momento tão difícil, o cuidado e a atenção da equipe fizeram toda a diferença."
  },
  {
    "id": 2,
    "name": "MARIA RITA",
    "location": "Cuité",
    "profileImage": oldWoman,
    "description": "A Plafam foi uma verdadeira bênção para a nossa família. Quando meu marido adoeceu, sentimos a diferença no cuidado e na atenção que recebemos."
  },
  {
    "id": 3,
    "name": "DEUSINEIA DOS SANTOS",
    "location": "Alagoa Nova",
    "profileImage": otherWoman,
    "description": "A Plafam mudou todo meu planejamento para o tratamento da doença que tenho, o acesso e os profissionais que me atendem nos exames são ótimos.",
  },
];

