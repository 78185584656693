import styles from "./dialog_balloon.module.scss";
import balloon from "../../assets/Shape.png";
import speaker from "../../assets/alto_falante_icon.png";
import meet from "../../assets/meet_icon.png";
import man_face from "../../assets/portrait-happy-smiley-man.png";
function DialogBalloon() {
  const renderCard = (text: string, image: string) => (
    <div className={styles.row}>
      <div className={styles.imageBackgroundBalloon}>
        <img src={balloon} alt="balloon" />
      </div>
      <div className={styles.containerBox}>
        <div className={styles.containerText}>
          <span>{text}</span>
        </div>
      </div>
      <div className={styles.containerTopImage}>
        <img src={image} alt="icon" />
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      <img className={styles.polygon} src={man_face} alt="man" />

      <div className={styles.banner}>
        {renderCard(
          "Planos especiais com características específicas para a necessidade da sua família.",
          meet
        )}
        {renderCard(
          "Descontos exclusivos em mais de 600 parceiros.",
          speaker
        )}

      </div>

    </div>
  );
}

export default DialogBalloon;
