import styles from "./footer.module.scss";
import wpp from "../../assets/zap_icon.png";
import social_networks from "../../assets/insta_solo.png";
import boleado from "../../assets/retangulo_boleado.png";

function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.roundedCircle}>.</div>
      <div className={styles.footerText}>
        <div>
          <img
            src={wpp}
            alt="wpp"
            style={{
              width: "1rem",
              filter: "brightness(0) invert(1)",
              position: "relative",
              top: "0.1rem",
              right: "0.2rem",
            }}
          />
          ASSISTÊNCIA EM VIDA
        </div>
        <p>83 3321-0510</p>
      </div>
      <div className={styles.footerText}>
        <div>
          <img
            src={wpp}
            alt="wpp"
            style={{
              width: "1rem",
              filter: "brightness(0) invert(1)",
              position: "relative",
              top: "0.1rem",
              right: "0.2rem",
            }}
          />
          <span>ASSISTÊNCIA PÓS-VIDA</span>
        </div>
        <p>83 3321-8988</p>
      </div>
      <div className={styles.footerText}>
        <div className={styles.socialIcon}>
          <img
            src={social_networks}
            alt="social_networks"
            style={{
              width: "1rem",
              height: "1rem",
              filter: "brightness(0) invert(1)",
              alignSelf: "end",
            }}
          />
          <p className={styles.socialText}>@planoplafam</p>
          <img className={styles.boleado} src={boleado} alt="boleado" />
        </div>
      </div>
    </div>
  );
}

export default Footer;
