import zap from "../../assets/zap_icon.png";
import style from './FloatingButton.module.scss';

const FloatingButton = () => {
  return (
    <a
      href="https://wa.me/+5583996460011"
      className={style.floatButton}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={zap} alt="whatsapp" />
    </a>
  );
};

export default FloatingButton;
