import { useLocation, useNavigate } from "react-router-dom";
import styles from "./tabs.module.scss";

type TabsRouteProps = {
  text: string;
  path: string;
};

interface TabsProps {
  array: TabsRouteProps[];
  onTabSelect: (index: number) => void;
  selectedTab: number;
  column?: boolean;
}

export function Tabs({ array, onTabSelect, selectedTab, column }: TabsProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const tabsComponents = array.map((item, index) => {
    return (
      <button
        type="button"
        key={`tab-${item.text}`}
        onClick={() => {
          onTabSelect(index);
          navigate(`${item.path}`);
        }}
        className={styles.buttonNavbar}
        style={
          location.pathname === item.path
            ? { backgroundColor: "white", borderRadius: "15px", fontWeight: "bold" }
            : {}
        }
      >
        {item.text}
      </button>
    );
  });

  return (
    <>
      {column ? (
        <div
          style={{ display: "flex", gap: "1.5rem", flexDirection: "column" }}
        >
          {tabsComponents}
        </div>
      ) : (
        <div style={{ display: "flex", gap: "0.5rem" }}>{tabsComponents}</div>
      )}
    </>
  );
}
