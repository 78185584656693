import React, { useState } from "react";
import emailjs from "emailjs-com";
import style from "./form_contact.module.scss";
import toast, { Toaster } from "react-hot-toast";
import { ClipLoader } from "react-spinners";

export function FormContact() {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .send(
        "service_byxoqqs",
        "template_9c2ijyt",
        formData,
        "2u0SBASDhW-WXINbT"
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success("Mensagem enviada com sucesso!");
        },
        (error) => {
          console.log(error.text);
          toast.error("Ocorreu um erro ao enviar a mensagem.");
        }
      )
      .finally(() => setLoading(false));
  };

  return (
    <div className={style.container}>
      <Toaster />
      <form onSubmit={handleSubmit}>
        <div className={style.containerForm}>
          <div className={style["form-group"]}>
            <label htmlFor="name">Seu nome*</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className={style["form-group"]}>
            <label htmlFor="email">Email*</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className={style.containerForm}>
          <div className={style["form-group"]}>
            <label htmlFor="phone">Celular*</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div className={style["form-group"]}>
            <label htmlFor="subject">Assunto*</label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        <div className={style["form-group"]}>
          <label htmlFor="message">Mensagem*</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <button className={style.button} type="submit">
          {loading ? <ClipLoader size={28} color="#fff" /> : "ENVIAR"}
        </button>
      </form>
    </div>
  );
}
