import { createBrowserRouter } from "react-router-dom";
import { PrivateLayout } from "../layout/PrivateLayout/PrivateLayout";
import { AuthorizedEstablishments, Home, Plafam, Plans } from "../pages";

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <PrivateLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/plafam",
        element: <Plafam />,
      },
      {
        path: "/credenciados",
        element: <AuthorizedEstablishments />,
      },
      {
        path: "/planos",
        element: <Plans />,
      }
    ],
  },
]);

export default routes;
