import { ListPlanProps } from "../../pages/plans/Plans";
import prataImage from "../../assets/silver.png";
import ouroImage from "../../assets/gold.png";
import rubiImage from "../../assets/ruby.png";
import esmeraldaImage from "../../assets/emerald.png";
import diamanteImage from "../../assets/diamond.png";
import prataModal from "../../assets/PRATA_PLAN.png";
import ouroModal from "../../assets/OURO_PLAN.png";
import diamanteModal from "../../assets/DIAMANTE_PLAN.png";
import rubiModal from "../../assets/RUBI_PLAN.png";
import esmeraldaModal from "../../assets/ESMERALDA_PLAN.png";

import style from "./list_plan.module.scss";
import { useState } from "react";

export function ListPlan({ tipo }: ListPlanProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  let imagem: string;
  let modalImagem: string;
  let descricao: string;

  switch (tipo) {
    case "prata":
      imagem = prataImage;
      modalImagem = prataModal;
      descricao = "PRATA";
      break;
    case "ouro":
      imagem = ouroImage;
      modalImagem = ouroModal;
      descricao = "OURO";
      break;
    case "diamante":
      imagem = diamanteImage;
      modalImagem = diamanteModal;
      descricao = "DIAMANTE";
      break;
    case "rubi":
      imagem = rubiImage;
      modalImagem = rubiModal;
      descricao = "RUBI";
      break;
    case "esmeralda":
      imagem = esmeraldaImage;
      modalImagem = esmeraldaModal;
      descricao = "ESMERALDA";
      break;
    default:
      imagem = "";
      modalImagem = "";
      descricao = "";
  }

  const handleListClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div onClick={handleListClick} className={style.medalha}>
        {imagem && (
          <img src={imagem} alt={descricao} className={style.imagem} />
        )}
        <div className={style.descricao}>{descricao}</div>
      </div>

      {isModalOpen && (
        <div className={style.modalOverlay}>
          <div className={style.modal}>
            <button className={style.closeButton} onClick={handleCloseModal}>
              &times;
            </button>
            <img
              src={modalImagem}
              alt={`Modal ${descricao}`}
              className={style.modalImage}
            />
          </div>
        </div>
      )}
    </>
  );
}
