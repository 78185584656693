import style from "./plafam.module.scss";
import About from "../../components/about";
import Info from "../../components/info";
import bannertopo from "../../assets/banner_topo.png";
import section from "../../assets/section.png";
import ImageSlider from "../../components/image_slider";
import flores_belas from "../../assets/flores_bellas.png";
import brenna from "../../assets/brenna.png";
import funeraria_sc from "../../assets/funeraria_sc.png";
import plafam_img from "../../assets/plafam.png";

function Plafam() {
  const images = [flores_belas, brenna, funeraria_sc, plafam_img];
  return (
    <>
      <div className={style.bannerTopo}>
        <img src={bannertopo} alt="banner" />
      </div>
      <div className={style.imageSlider}>
        <ImageSlider width="80vw" images={images} />
      </div>
      
      <About />

      <div className={style.waveabout}>
        <div className={style.wave}>
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
              className={style.wave_fill}
            ></path>
          </svg>
        </div>
      </div>

      <Info />

      <div className={style.section}>
        <img src={section} alt="section" />
      </div>
    </>
  );
}

export default Plafam;
