import { CaretRight, CaretLeft } from "@phosphor-icons/react";
import styles from "./carousel.module.scss";
import capa2 from "../../assets/capa 2.png";
import capa4 from "../../assets/capa_plafam.png";
import capa1 from "../../assets/capa4.png";
import Slider from "react-slick";

const Carousel = () => {
  const images = [capa1, capa2, capa4];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    nextArrow: (
      <button className={styles.nextButton}>
        <CaretRight size={28} weight="bold" />
      </button>
    ),
    prevArrow: (
      <button className={styles.prevButton}>
        <CaretLeft size={28} weight="bold" />
      </button>
    ),
    draggable: false,
    swipe: false,
    pauseOnHover: false,
  };

  return (
    <div className={styles.carousel}>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div className={styles.slide} key={index}>
            <img src={image} alt={`Slide ${index}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
