import banner_retangulo_1 from "../../assets/credenciado-banner-1.png";
import banner_retangulo_2 from "../../assets/credenciado-banner-2.png";
import banner_retangulo_3 from "../../assets/credenciado-banner-3.png";
import style from "./authorized_establishments.module.scss";
import search from "../../assets/search.png";
import wpp from "../../assets/zap_icon.png";
import social_networks from "../../assets/insta_solo.png";
import boleado from "../../assets/retangulo_boleado.png";
import family from "../../assets/family.png";
import background3 from "../../assets/background-page3.png";
import Modal from "../../components/modal";
import useAuthorizedEstablishments from "../../hooks/useAuthorizedEstablishments";
import useScrollReset from "../../hooks/useScrollReset";
import ImageSlider from "../../components/image_slider";

const AuthorizedEstablishments = () => {
  useScrollReset();

  const {
    filteredData,
    isOpen,
    setIsOpen,
    inputRef,
    handleSearch,
    handleInputChange,
    handleKeyDown,
  } = useAuthorizedEstablishments();

  const images = [banner_retangulo_1, banner_retangulo_2, banner_retangulo_3];

  return (
    <div
      className={style.root}
      style={{ backgroundImage: `url(${background3})` }}
    >
      <div className={style.container}>
        <div className={style.searchCredenciados}>
          <h1>CREDENCIADOS</h1>

          <div style={{ position: "relative" }}>
            <input
              ref={inputRef}
              name="search"
              placeholder="Pesquise pela especialidade..."
              type="text"
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
            <div
              onClick={handleSearch}
              style={{
                width: "1.5rem",
                top: "0.3rem",
                right: "1rem",
                position: "absolute",
              }}
            >
              <img
                style={{ cursor: "pointer" }}
                src={search}
                alt="search"
                width={24}
              />
            </div>
          </div>
          <button
            style={{
              marginTop: "1rem",
              backgroundColor: "#478b4b",
              borderRadius: "17.5px",
              color: "white",
              padding: "0.5rem 1rem",
              cursor: "pointer",
              border: "1px solid white",
            }}
            onClick={handleSearch}
          >
            Ver todos
          </button>
          <h4>As melhores especialidades</h4>
        </div>

        <div className={style.squareBanner}>
          <ImageSlider width="50%" images={images} />
        </div>

        <div className={style.familyText}>
          <div className={style.text}>
            <h1>
              Planejar o futuro também é <b>cuidar dos seus.</b>
            </h1>
          </div>
          <div className={style.family}>
            <img src={family} alt="family" />
          </div>
        </div>

        <div className={style.footer}>
          <div className={style.footerText}>
            <div>
              <img
                src={wpp}
                style={{
                  width: "1rem",
                  filter: "brightness(0) invert(1)",
                  position: "relative",
                  top: "0.1rem",
                  right: "0.2rem",
                }}
                alt="wpp"
              />
              ASSISTÊNCIA EM VIDA
            </div>
            <div>83 3321-0510</div>
          </div>
          <div className={style.footerText}>
            <div>
              <img
                src={wpp}
                style={{
                  width: "1rem",
                  filter: "brightness(0) invert(1)",
                  position: "relative",
                  top: "0.1rem",
                  right: "0.2rem",
                }}
                alt="wpp"
              ></img>
              ASSISTÊNCIA PÓS-VIDA
            </div>
            <div>83 3321-8988</div>
          </div>
          <div className={style.footerText}>
            <div className={style.socialIcon}>
              <img
                src={social_networks}
                alt="social_networks"
                style={{
                  width: "1rem",
                  height: "1rem",
                  filter: "brightness(0) invert(1)",
                  alignSelf: "end",
                }}
              />
              <p className={style.socialText}>@planoplafam</p>
              <img className={style.boleado} src={boleado} alt="boleado" />
            </div>
          </div>
        </div>

        {isOpen && <Modal setIsOpen={setIsOpen} data={filteredData} />}
      </div>
    </div>
  );
};

export default AuthorizedEstablishments;
