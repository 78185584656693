import styles from "./description.module.scss";
import grandman_img from "../../assets/grandma_with_hearth.png";
import { WaveSVG } from "../SVG";

function Description() {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.text}>
          <b>O Plafam - Plano Familiar -</b> oferece acesso a mais de 600
          parceiros em toda Paraíba. Desfrute de descontos exclusivos
          em uma ampla gama de serviços, desde profissionais da saúde até
          estabelecimentos educacionais, farmácias, óticas e muito mais.
          <p>
            Sua família <b>merece o melhor</b>, escolha o Plafam para garantir
            benefícios em todas as áreas da vida!
          </p>
        </div>

        <div className={styles.image}>
          <img src={grandman_img} alt="plafam" />
        </div>
      </div>
      <WaveSVG />
    </>
  );
}

export default Description;
