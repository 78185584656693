import Banner from '../../components/banner'
import Carousel from '../../components/carousel'
import Description from '../../components/description'
import DialogBalloon from '../../components/dialog_balloon'
import Footer from '../../components/footer'

function Home() {
  return (
    <>
      <Carousel />
      <Banner />
      <Description />
      <DialogBalloon />
      <Footer />
    </>
  )
}

export default Home