import React from "react";
import styles from "./modal.module.scss";
import { X } from "@phosphor-icons/react";
import { FilteredDataProps } from "../../types/types";
import ModalContent from "./components/modal-content";

interface IModal {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data: FilteredDataProps[];
}

const Modal: React.FC<IModal> = ({ setIsOpen, data }) => {
  return (
    <>
      <div className={styles.darkBG} onClick={() => setIsOpen(false)} />
      <div className={styles.centered}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <h5 className={styles.heading}>Resultados</h5>
          </div>
          <button className={styles.closeBtn} onClick={() => setIsOpen(false)}>
            <X style={{ marginBottom: "-3px" }} />
          </button>
          <ModalContent data={data} />
        </div>
      </div>
    </>
  );
};

export default Modal;
