import styles from "./testimony.module.scss";
import feed from "../../assets/feed-julho.png";
import aspas from "../../assets/aspas.png";
import { useEffect } from "react";

export type TestimonyProps = {
  id: number;
  name: string;
  location: string;
  profileImage: string;
  description: string;
};

interface ITestimony {
  data: TestimonyProps[];
}

const ElfSightApp = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://static.elfsight.com/platform/platform.js";
    script.defer = true;
    script.setAttribute('data-use-service-core', '');
    document.body.appendChild(script);
    
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div className="elfsight-app-19cb0191-a9d3-4bd0-9c53-9e9d922f1d97" data-elfsight-app-lazy>
  </div>;
};

const Testimony: React.FC<ITestimony> = ({ data }) => {
  return (
    <div className={styles.testimonyContainer}>
      <div className={styles.container}>
        {data?.map((item) => (
          <div className={styles.testimony} key={item.id}>
            <img className={styles.aspas} src={aspas} alt="aspas" />
            <div className={styles.profileImage}>
              <img src={item.profileImage} alt="profile" />
            </div>
            <p>{item.description}</p>
            <div className={styles.author}>
              <span className={styles.name}>{item.name}</span>
              <span className={styles.location}>{item.location}</span>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.instagramContainer}>
        <h4 style={{ zIndex: 999999 }}>Fique por dentro das novidades:</h4>
          <ElfSightApp />
      </div>
    </div>
  );
};

export default Testimony;
