import { useRef, useState } from "react";
import Navbar from "../navbar";
import { Outlet } from "react-router-dom";

const PrivateLayout = () => {
  const [selectedItem, setSelectedItem] = useState(0);
  const scrollRefs = useRef<(HTMLDivElement | null)[]>(Array.from({length: 4}, () => null));

  const handleTabSelect = (index: number) => {
    setSelectedItem(index);
    if (scrollRefs.current[index]) {
      scrollRefs.current[index]!.scrollIntoView({behavior: 'smooth'});
    }
  };

  return (
    <main className="ly-private-layout__container--routes">
      <Navbar selectedTab={selectedItem} onTabSelect={handleTabSelect}/>
      <Outlet />
    </main>
  );
};

export { PrivateLayout };
